@font-face {
    font-family: 'GoldenDragon';
    src: url('../../public/fonts/GoldenDragon.ttf') format('truetype');
    font-display: swap;
}
// @font-face {
//     font-family: 'Dancing Script';
//     src: url('../../public/fonts/DancingScript.ttf') format('truetype');
//     font-display: swap;
// }

@media only screen and (max-width: 1200px) {

    .web-submenu{
      min-width: 0px;
      max-width:0px;
      overflow-x: hidden;
      overflow-y: hidden;
      display: none;
    }
  
   .menu-width{
      max-width: 0%;
      min-width: 0%;
    }
}
@keyframes slide {
0% {transform:translateX(100%);}
100% {transform:translateX(-100%);}
}
.sidebar {
    position: fixed;
    top: 0;
    right: -246px; /* Start the sidebar off-screen */
    width: 250px;
    height: 100%;
    background: #f1f1f1;
    transition: right 0.4s ease;
    width:0px
}

.sidebar.show {
    right: 0; /* Move the sidebar to the right when the show class is applied */
    width: 60vw;
}

.glaze-effect:after{
    content:'';
    top:0;
    transform:translateX(100%);
    width:100%;
    height:100%;
    position: absolute;
    z-index:1;
    animation: slide 5s infinite;
        
    /* 
    CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
    */
    // background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.3)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
    // background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
    // background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
    // background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
    // background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}
    
  
.home-banner-white-box{
    max-width: 60vw;
    min-width: 60vw;
    max-height: 30vh;
}

.homepage-slider-img{
    min-width: 210px;
    max-width: 210px;
    min-height: 278px;
    max-height: 278px;
    border-radius: 20px;
    margin-right: 20px;
    object-fit: cover;
    // min-height: 225px;
}
.homepage-slider-padding{
    margin-right: 20px;
}
.homepage-slider-section{
    padding-top: 5%; 
    margin: 0 15% 3% 10%;
}
.font-size-32-20{
    font-size: 32px;
}
.homepage-img-section{
    margin:1% 50px
}
.font-size-42-26{
    font-size: 82px;
}
.destination-word-homepage{
    border-bottom: 6px solid #12765e;
    max-width: 18%;
    margin: 15px 0 0 0;
    padding-bottom: 8px;
    text-decoration: bolder;
    font-family: Lato;
    letter-spacing: 2px;
    font-size: 18px;
}
.where-like-go-homepage{
    margin-top: 3%;
}
.font-size-24-18{
    font-size: 19px;
}
.flex-row-column{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.plan-your-trip-btn{
    margin:2% 0 10% 0;
    min-height: 60px;
    width: 28%;
}
.search-box-home{
    min-height:60px;
    max-height: 60px;
    margin-right: 20%;
    margin: 2% 2% 10% 0;
    width: 70%;
}
.font-size-90-20{
    font-size: 90px;
    font-family: 'Kalnia';
    text-transform: capitalize;
}
.carasoul-img{
    max-width:50%; 
    max-height:50%;
    margin-top: 5%;
}
.carasoul-description{
    padding: 1% 20% 0 20%;
}
.bg-color-imgs{
    max-width: 220px;
    min-width: 220px;
    min-height: 230px;
    max-height: 230px;
}
.bg-img-width{
    width: 55%;
}
.bg-img-width-travller{
    width: 100%;
    flex-wrap: nowrap;
}
.travellers-img-home{
    min-height: 200px;
    max-height: 200px;
    min-width: 230px;
    max-width: 230px;
}
.travellers-border{
    margin: 0 15% 0 14%;
    border-radius: 30px;
}
.creative-home{
    max-width:100%; 
    object-fit:contain;
    min-width: 100%;
    max-height: 500px;
}
.contact-btn-home{
    margin-top:5%;
    min-width: 20%;
    min-height: 5vh;
}
.logo-footer{
    max-width: 20%;
}
.font-size-20-13{
    font-size: 20px;
}
.font-size-20-14{
    font-size: 18px;
}
.font-size-22-18{
    font-size: 22px;
}
.footer-social-icons{
    margin-top: 2%;
    margin-bottom: 4%;
}
.padding-top-6-3{
    padding-top: 3%;
}
.searchpage-header-gap{
    margin-top: 4.5%;
}
.header-logo{
    max-width:25%;
    min-width: 25%;
    margin: 0.5% 0 0 2%;
}
.your-dream-3steps{
    padding: 5% 10% 5% 10%
}
.search-page-explore-with-WT{
    padding: 2% 20%;
}
.padding-2-1{
    padding: 1%;
}
.top-trips-text-searchpg{
    margin: 2% 0 2% 0
}
.font-size-48-24{
    font-size: 42px;
}
.search-card-img{
    max-width: 100%;
    min-width: 100%;
    max-height:460px;
    min-height:510px; 
    overflow: hidden; 
    border-radius:10px 0 0 10px; 
    object-fit: cover;
}
.search-card{
    min-width: 70%;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    min-height: 350px;
    max-height: 510px;
    margin: 10px 0;
    box-shadow: 0px 0px 5px 0px rgba(63, 65, 68, 0.3);
    color: black;      
}
.width-100-50{
    min-width: 50%;
    max-width: 50%;
}
.margin-5-0{
    // margin-left: 2%;
}
.width-50-100{
    min-width: 50%;
    max-width: 50%;
    margin-top: 0.5em;
}
.packagepg-top{
    margin-top: 4%;
    padding: 0 5%;
}
.packagepg-3btns{
    padding:2% 5%
}
.stars-1{
    padding:0 0 4% 0
}
.packagepg-img{
    min-height:650px;
    max-height: 400px;
    max-width:48%; 
    min-width:48%; 
    object-fit:cover;
}
.packagepg-title{
    font-size:28px;
    padding-top:2%; 
    margin:0 10%;
}
.packagepg-desc{
    padding-top:1%;
    margin:0 10%;
}
.package-includes{
    margin-top: 3%;
}
.packagepg-request-info{
    margin: 0% 0 2% 0
}
.page3-inset{
    inset: 0.5rem -0.5rem 0.5rem 1.8rem
}
.page3-trip-highlings-img-sections{
    padding-right:1%;
    max-width: 30%;
    min-width: 30%;
}
.height-img-define{
    min-height:250px; 
    max-height:250px;
}
.add-margin-btm{
    margin-bottom:1%;
}
.padding-2-5{
    padding:5% 5%;
}
.itinery-img{
    min-width: 30vw;
    max-width:30vw;
    max-height: 300px;
    object-fit: cover;
}
.padding-package-pg-green-section{
    padding:0% 0% 2% 0
}

.main-img{
    max-height:90vh
}
.display-mobile{
    display: none;
}
.display-web{
    display: block;
}
/* fixed social*/
#fixed-social {
    position: fixed;
    top: 80%;
    right: 55px;
 }
 .social-menu ul{
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
 }
.social-menu ul li{
    list-style: none;
    margin: 10px 0;
}

.social-menu ul li .fab{
    font-size: 30px;
    line-height: 60px;
    transition: .3s;
    color: #000;
}
.social-menu ul li .fa-solid{
    font-size: 30px;
    line-height: 60px;
    transition: .3s;
    color: #000;
}

.social-menu ul li .fab:hover{
    color: #fff;
}
.social-menu ul li .fa-solid:hover{
    color: #fff;
}

.social-menu ul li a{
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    transition: .6s;
    box-shadow: 0 5px 4px rgba(0,0,0,.5);
}

.social-menu ul li a:hover{
    transform: translate(0, -10%);
}

.social-menu ul li:nth-child(1) a:hover{
    background-color: rgba(0, 0, 0, 0.829);
}
.social-menu ul li:nth-child(2) a:hover{
    background-color: #5affa1;
}
.social-menu ul li:nth-child(3) a:hover{
    background-color: #0077b5;
}
.social-menu ul li:nth-child(4) a:hover{
    background-color: #000;
}


.trip-highlight-section{
    width: 90%;
    padding:3% 0%;
    margin: 0 7%;
}
.page3-highlight-img{
    max-width:97%;
    min-width:97%; 
    min-height:320px; 
    max-height:320px;
    padding-left: 1%;
}
.trip-highlight-text{
padding:20% 8% 2%; 
}
.margin-desktop-5{
margin: 0 10%;
}
.width18-14{
    min-width:14%; 
    max-width:14%;
}
.location-width{
max-width:45px;
}
.font-size-32-16{
font-size: 24px;
}
.font-size-72-26{
font-size: 72px;
}
.img-cards-home{
    max-height:280px;
    max-width:100%; 
    min-width:100%; 
}
.carasoul-head{
    max-width:80%; 
    margin:0 10%; 
    min-width:80%;
    margin-bottom: 5%;
}
.home-travellers-head{
    margin:5% 0 5% 0;
}
.location-width-head{
    width: 5%;
}
.home-slider-h2{
    padding-left:4%;
}
.ul-footer {
    list-style-type: none;
    padding-top: 1em;
    display: inline-flex;
    flex-wrap: wrap;
  }

  .ul-footer > li {
    position: relative;
    padding-left: 3em; /* Adjust this value to control space between bullet point and text */
    padding-right: 3em;
    text-align: left;
    white-space: normal;
    line-break: auto;
  }

  .ul-footer > li::before {
    content: '\2022'; /* Bullet point character */
    color: #fff; /* Bullet point color */
    position: absolute;
    left: 0;
  }
  .max-width-70-90{
    max-width: 70%;
    padding:5% 0;
  }
  .margin-left-web{
    margin-left: 5%;
  }
  .dropdown>.dropdown-menu,
  .dropdown.open>.dropdown-menu {
     opacity: 0;
     visibility: hidden;
     transition: all ease 0.2s;
     font-weight: bolder;
  }
  .dropdown:hover>.dropdown-menu,
  .dropdown.open:hover>.dropdown-menu { 
     opacity: 1;
     visibility: visible; 
  }
  .dropdown-menu li a:hover {
    color: #12765e;
    font-weight: bolder;
}
 /*end fixed social*/
 .large-heading{
    font-size: 25px;
    font-family: "Lato";
    font-weight: bolder;
    color: #12765e;
    text-align: left;
    margin-bottom: 2vh;
    margin-left: 1%;
 }
 .subheading{
    font-size: 14px;
    font-family: "Lato";
    color: black;
    font-weight: bolder;
 }
 .col-sm-3{
    margin: 0 2%;
    flex: 0 0 30%;
 }
 .dropdown-category{
    display:flex;
    flex-direction:column;
    align-content:center;
    justify-content:center; 
 }
.menu-web{
    display:flex;
    flex-direction:row;
    flex-wrap: nowrap;
}

.form-popup-bg {
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
  .form-popup-bg {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;
    overflow-y: auto;
    z-index: 10000;
  }
  .form-popup-bg.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s;
  }
  .close-button {
    background:none;
    color: black;
    width: 40px;
    height: 40px;
    // position: absolute;
    // top: 0;
    // right: 0;
    cursor: pointer;
    border: solid 1px #fff;
  }
  
  .form-popup-bg:before{
      content:'';
      background-color: #fff;
    opacity: .25;
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .form-container{
    min-width: 50vw;
    max-width: 60vw;
    background-color: white;
    min-height: 90vh;
    max-height: 90vh;
  }
  .package-top1{
    display: flex;
    flex-direction: row;
  }
  .pg-detail-maxheight{
    max-height: 15vh;
    max-width: 35%;
  }
  .width-40-100{
    width: 48%;
  }
  .fn-ln-css{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 9999999;
  }
  .contact-form-submit-btn{
    width: 40%;
  }


.calendar-box {
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .calendar-title {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .calendar {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    position: absolute;
    z-index: 1;
    display: none;
  }
  
  .header {
    background-color: #12765e;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  #monthYear{
    color: white;
  }
  
  #prevBtn,
  #nextBtn {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 16px;
  }
  
  #monthYear {
    font-size: 18px;
    font-weight: bold;
  }
  
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    // gap: 5px;
    padding: 10px;
  }
  
  .day {
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .day.current {
    background-color: #3498db;
    color: white;
  }
  
  .day.selected {
    background-color: #2ecc71;
    color: white;
  }
  
  #dateInput {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
  }

  .carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: white;
  }
  
  .carousel-arrow.left {
    left: 10px;
    z-index: 4;
  }
  
  .carousel-arrow.right {
    right: 10px;
  }
  .font-size-36-20{
    font-size: 36px;
  }
  .flex-row-reverse{
    flex-direction: row-reverse;
  }
  .wrap-nowrap{
    flex-wrap: nowrap;
  }
  .menu-width{
    max-width: 70%;
  }
  .web-submenu{
    max-width:1000px;
    overflow-x: auto;
    overflow-y: auto;
  }
  .flex-30-20{
    flex: 1 0 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hover-color:hover{
    background-color: #f0fffc;
    color:black;
  }
  
.image-smudge-effect::after {
    // content: '';
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // background:  rgba(0, 0, 0, 0.3);
    // z-index: 4;
    // margin-bottom: 5%;
}

@media only screen and (max-width: 1200px) {
    .itinery-img{
        min-width: 100%;
        max-width:100%;
    }
    .margin-top-4vh{
        margin-top: 4vh;
    }
    .flex-30-20{
        flex: 1 0 30%;
      }
  .web-submenu{
    min-width: 0px;
    max-width:0px;
    overflow-x: hidden;
    overflow-y: hidden;
    display: none;
  }

 .menu-width{
    max-width: 0%;
    min-width: 0%;
  }
  .wrap-nowrap{
    flex-wrap: wrap;
  }
    .font-size-36-20{
        font-size: 20px;
      }
    .package-top1{
        display: flex;
        flex-direction: column;
      }
    .form-container{
        min-width: 100vw;
        max-width: 100vw;
        background-color: white;
        min-height: 100vh;
        max-height: 100vh;
        overflow-y: scroll;
        padding-bottom: 10vh;
      }
    .contact-form-submit-btn{
        width: 90%;
    }
    .fn-ln-css{
        display: flex;
        flex-direction: column;
      }
    .width-40-100{
        width: 100%;
      }
    .pg-detail-maxheight{
        max-height: 25vh;
        min-width: 100%;
        object-fit: cover;
    }
    .margin-left-web{
        margin-left: 0%;
    }
    .max-width-70-90{
        min-width: 100%;
        // max-width: 90%;
        padding: 5% 10%;
    }
    .location-width-head{
        width: 12%;
    }
    .home-banner-white-box{
        max-width: 90vw;
        min-width: 80vw;
        max-height:30vh;
    }
    .homepage-slider-img{
        border-radius: 20px;
        object-fit: cover;
        display: inline-block;
        min-width: 200px;
        margin-left: 20px;
        margin-right: 0;
        max-width: 200px;
        min-height: 265px;
    }
    .homepage-slider-padding{
        margin-left: 20px;
    }
    .homepage-slider-section{
        padding-top: 5%; 
        margin: 0 0 5% 0;
    }
    .font-size-32-20{
        font-size: 20px;
    }
    .homepage-img-section{
        margin: 0;
        padding:3% 0;
    }
    .font-size-42-26{
        font-size: 26px;
    }
    .destination-word-homepage{
        border-bottom: 4px solid #12765e;
        max-width: 42%;
        margin: 10px 0 0 0;
        padding-bottom: 10px;
        text-decoration: bolder;
        font-family: Lato;
        letter-spacing: 2px;
        font-size: 14px;
    }
    .where-like-go-homepage{
        margin-top: 5%;
    }
    .font-size-24-18{
        font-size: 14px;
    }
    .font-size-48-24{
        font-size: 24px;
    }
    .flex-row-column{
        display: block;
        // flex-direction: column;
    }
    .plan-your-trip-btn{
        margin:-4% 0 8% 0;
        min-height: 20px;
        width: 100%;
    }
    .search-box-home{
        min-height:30px;
        margin: 6% 0 10% 0;
        width: 100%;
    }
    .font-size-90-20{
        font-size: 20px;
        text-transform: uppercase;
        font-family: 'Lato';
    }
    .carasoul-img{
        max-width:80%; 
        max-height:100%;
        margin-top: 2%;
    }
    .carasoul-description{
        padding: 5% 0 0 0;
    }
    .bg-color-imgs{
        max-width: 90px;
        min-width: 90px;
        min-height: 100px;
        max-height: 100px;
        margin-left: 10px;
    }
    .bg-img-width{
        width: 88%;
    }
    .travellers-img-home{
        min-height: 100px;
        max-height: 100px;
        min-width: 100%;
        max-width: 100%;
    }
    .bg-img-width-travller{
        width: 100%;
        flex-wrap: wrap;
    }
    .creative-home{
        max-width:100%; 
        object-fit:fill;
        max-height: 800px;
    }
    .contact-btn-home{
        margin-top:15%;
    }
    .font-size-20-13{
        font-size: 13px;
    }
    .font-size-20-14{
        font-size: 14px;
    }
    .font-size-22-18{
        font-size: 18px;
    }
    .footer-social-icons{
        margin-top: 8%;
        margin-bottom: 0;
    }
    .padding-top-6-3{
        padding-top: 6%;
    }
    .searchpage-header-gap{
        margin-top: 15%;
    }
    .header-logo{
        // ZZAA
        max-width:55%;
        // max-width: 42%;
    }
    .your-dream-3steps{
        padding: 5% 10% 0 10%;
        font-family: Lato;
        text-transform: uppercase;
    }
    .search-page-explore-with-WT{
        padding: 5%;
    }
    .padding-2-1{
        padding: 2%;
    }
    .top-trips-text-searchpg{
        margin: 7% 0 4% 0
    }
    .search-card-img{
        max-width: 100%; 
        min-height: 250px;
        max-height: 250px;
        object-fit: cover;
        min-width: 100%; 
        overflow: hidden; 
        border-radius:10px 10px 0 0; 
    }
    .search-card{
        min-width: 90%;
        max-width: 80%;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 10px;
        // min-height: 720px;
        max-height: 865px;
        margin: 10px 0;
        box-shadow: 0px 0px 5px 0px rgba(63, 65, 68, 0.3);
        color: black;      
    }
    .width-100-50{
        min-width: 100%;
        max-width: 100%;
    }
    .margin-5-0{
        margin-left: 0%;
    }
    .width-50-100{
        min-width: 100%;
    }
    .packagepg-top{
        margin-top: 15%;
        padding: 0 5%;
    }
    .packagepg-3btns{
        padding:5% 0 1% 0
    }
    .stars-1{
        padding:5% 0
    }
    .packagepg-img{
        min-height:300px;
        max-width:100%; 
        min-width:100%; 
        object-fit:cover;
    }
    .packagepg-title{
        font-size:21px;
        padding-top:2%; 
        margin:0 3%;
    }
    .packagepg-desc{
        padding-top:2%;
        margin:0 3%;
    }
    .package-includes{
        margin-top: 5%;
    }
    .packagepg-request-info{
        margin: 5% 0
    }
    .page3-inset{
        inset: 0.5rem -0.5rem 0.5rem 0.8rem
    }
    .page3-trip-highlings-img-sections{
        padding-right:5%;
        max-width: fit-content;
        min-width: auto;
    }
    .height-img-define{
        min-height:240px; 
        max-height:240px;
    }
    .add-margin-btm{
        margin-bottom:5%;
    }
    .padding-2-5{
        padding:2% 5%;
    }
    .itinery-img{
        max-width:100%;
        min-width: 100%;
    }
    .padding-package-pg-green-section{
        padding:0% 0% 7% 0
    }
    .travellers-border{
        margin: 0 2%;
        border-radius: 30px;
    }
    // ZZAA
    .main-img{
        min-height: 60vh;
        max-height:80vh
    }
    .display-mobile{
        display: block;
    }
    .trip-highlight-section{
        width: 100%;
        overflow-x: scroll;
        margin: 0;
    }
    .page3-highlight-img{
        max-width:310px;
        min-width:310px; 
        min-height:220px; 
        max-height:220px;
    }
    .trip-highlight-text{
      padding:3% 8%; 
    }
    .margin-desktop-5{
      margin: 0 0%;
    }
    .width18-14{
        min-width:18%; 
        max-width:18%;
    }
    .location-width{
      max-width:30px;
    }
    .font-size-32-16{
      font-size: 16px;
    }
    .font-size-72-26{
      font-size: 26px;
    }
    #fixed-social {
        display: none;
    }
    .img-cards-home{
        max-height:222px;
        max-width:100%; 
        min-width:100%;
    }
    .carasoul-head{
        max-width:90%; 
        margin:0 5%; 
        min-width:90%;
    }
    .display-web{
        display: none !important;
    }
    .home-travellers-head{
        margin:10% 0 0 0;
    }
    .home-slider-h2{
        padding-left:5%;
    }
    .object-fit-contain-mobile{
        object-fit: scale-down;
    }
}
