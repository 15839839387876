.App {
  text-align: center;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  overflow-x: hidden !important;
  font-family: 'Montserrat', serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
h2{
  font-family: 'Montserrat';
}
div::-webkit-scrollbar {
  display: none;
}

/* For Chrome */
div {
  scrollbar-width: none;
}

.shadow-on-hover:active{
    box-shadow: 0 0 8px rgba(117, 117, 117, 0.8); 
}
body{
  color: #3f4144;
}