:root {
	--star-size: 19px;
	--star-color: lightgrey;
	--star-background: #fc0;
  }
  
.Stars {
	--percent: calc(var(--rating) / 5 * 100%);
	
	display: inline-block;
	font-size: var(--star-size);
	line-height: 1;
	
	&::before {
	  content: '★★★★★';
	  letter-spacing: 3px;
	  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
	  -webkit-background-clip: text;
	  -webkit-text-fill-color: transparent;
	}
  }

  
.button-tab {
	display:block;
	width:100px;
	height:50px;
	line-height:50px;
	text-align:center;
	text-decoration: none;
	color:"#767676"
	}

	a.active {
	border-bottom: 5px solid #12765e;
	color: grey;
	}
	a{
	color: black;
	}

	div[class*="content-"] { 
	display:none;
	}

	div.active { 
	display:block;
	}

	.slider {
	max-width: 100%;
	background-color: white;
	}

	.slide {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.slick-dots {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 1rem 0;
	list-style-type: none;
	li {
		margin: 0 0.25rem;
	}
	button {
		display: block;
		width: 1rem;
		height: 1rem;
		padding: 0;
		border: none;
		border-radius: 100%;
		background-color: black;	
	}
	li.slick-active button {
		background-color: #12675e;	
	}
}



$blue: #3498db;
$green: #1abc9c;
$red: #c0392b;
$stone: #bdc3c7;
$slate: darken($stone, 50);
$yellow: #f1c40f;

$curveDecelerateMax: cubic-bezier(0, 0, 0, 1);

.button-expand {
	all: inherit;
	box-sizing: border-box;
	background-color: white;
	// padding: 1rem 0;
	min-height: 60px;
	font-size: 1.25em;
	width: 100%;
	font-weight: 600;
	user-select: none;
	cursor: pointer;
}
.button__icon {
	display: inline-block;
	font: 1.5rem monospace;
	width: 1.5rem;
	height: 1.5rem;
	transition: transform 300ms $curveDecelerateMax;
	transform: rotate(90deg);
	[aria-expanded=true] & {
		transform: rotate(270deg);
	}
}

.content-expand {
	background: lighten(white, 30);
	display: grid;
	grid-template-rows: 1fr;
	transition: grid-template-rows 1000ms $curveDecelerateMax;
	transition-duration: 3000ms;
	// transition: max-height 0.3s ease-in-out;
}
.content-expand[hidden] {
	grid-template-rows: 0fr;
}

.content__container {
  overflow: hidden;
	
	ul {
	  list-style: none;
	  padding: 0 0 0 2.5rem;
	}
	li {
		margin: 1rem 0;
	}
}