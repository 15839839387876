
.swiper {
    max-width: 80%;
    aspect-ratio: 5/3;
    // z-index: 10;
  }
  
  .swiper-pagination {
    transform: translateY(-10px);
    // z-index: 10;
    --swiper-pagination-color: #7edd90;
    --swiper-pagination-bullet-size: 12px;
    --swiper-pagination-bullet-horizontal-gap: 5px;
  }
  
  .swiper {
    display: grid;
    // grid-template-columns: 40% 60%;
    place-items: center;
    gap: 15px;
    // padding: 20px 40px 20px 20px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    // background: #23232a;
    // box-shadow: inset 18px 18px 8px rgba(0, 0, 0, 0.2),
    //   inset -10px -18px 8px rgba(255, 255, 255, 0.1);
  }

  .swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  /* ANIMATED BACKGROUND */
  
  .particles {
    position: absolute;
    display: flex;
    // z-index: 1;
    padding: 0 10px;
    // z-index: 5;
  }
  
  .particles span {
    position: relative;
    bottom: 30px;
    width: 30px;
    height: 30px;
    background: #7edd90;
    box-shadow: 0 0 0 10px #7edd9044, 0 0 50px #7edd90, -100px 0 #475c9a99,
      100px 0 #475c9a99;
    margin: 0 4px;
    border-radius: 50%;
    animation: animate calc(190s / var(--i)) ease infinite;
  }
  
  .particles span:nth-child(2n) {
    background: #475c9a;
    box-shadow: 0 0 0 10px #475c9a44, 0 0 50px #475c9a, -100px 0 #7edd9099,
      100px 0 #7edd9099;
  }
  
  .particles span:nth-child(3n) {
    background: #9a4772;
    box-shadow: 0 0 0 10px #9a477244, 0 0 50px #9a4772, -100px 0 #7edd9099,
      100px 0 #7edd9099;
  }
  
  @keyframes animate {
    0% {
      transform: translateY(120vh) scale(0) rotate(180deg);
    }
  
    20% {
      transform: translateY(100vh) scale(1) rotate(0deg);
    }
  
    100% {
      transform: translateY(-120vh) scale(0.5) rotate(360deg);
    }
  }
  
  
  @media (max-width: 800px) {
     main {
      padding: 0 0;
    }
    
    .swiper {
      max-width: 650px;
    }
  }