#after-position-relative::after{
  position: relative;
}

.range-slider {
  width: 300px;
  text-align: center;
  position: relative;
  .rangeValues {
    display: block;
  }
}

input[type=range] {
  -webkit-appearance: none;
  border: 1px solid white;
  width: 300px;
  position: absolute;
  left: 0;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;

}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #12765e;
  margin-top: -4px;
    cursor: pointer;
      position: relative;
    z-index: 1;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type=range]::-moz-range-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type=range]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
  
}


/*hide the outline behind the border*/

input[type=range]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type=range]::-ms-track {
  width: 300px;
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent;
    z-index: -4;

}

input[type=range]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}

input[type=range]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}

input[type=range]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
}

input[type=range]:focus::-ms-fill-lower {
  background: #888;
}

input[type=range]:focus::-ms-fill-upper {
  background: #ccc;

}
// input[type=range] {
//   width: 100%;
//   background: linear-gradient(to right, green 0%, green calc((100% - 500px) / 9500 * 100), #ccc calc((100% - 500px) / 9500 * 100), #ccc 100%);
//   outline: none;
//   // -webkit-appearance: none;
//   height: 10px;
//   border-radius: 5px;
//   margin-top: 10px;
// }

// input[type=range]::-webkit-slider-thumb {
//   -webkit-appearance: none;
//   width: 20px;
//   height: 20px;
//   border-radius: 50%;
//   background: #fff;
//   border: 1px solid #ccc;
//   box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
//   cursor: pointer;
//   margin-top: -5px;
// }


.button {
    float: left;
    margin: 0 0 0 0;
    min-width: 84px;
    height: 27px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #12765e;
    border-radius: 30px;
    margin: 2% 1%;
    font-weight: 600;
    padding: 0 4px;
  }
  
  .button label,
  .button input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    word-wrap: nowrap;
  }
  
  .button input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
  }
  
  .button input[type="radio"]:checked + label {
    background: #12765e;
    border-radius: 30px;
    color: white;
  }
  
  .button label {
    cursor: pointer;
    z-index: 90;
    line-height: 1.8em;
  }




  section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
  }
.tile {
    height: 150px;
    min-width: 150px;
    max-width: 30%;
    position: relative;
    flex: 1;
  }
  .tile-ctr {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 3px solid transparent;
    box-shadow: 15px 15px 25px rgba(2, 28, 53, 0.05);
    transition: transform 0.4s ease;
    overflow: hidden;
  }
  input[type='checkbox'] {
    -webkit-appearance: none;
    outline: none;
    z-index: 1;
    position: relative;
  }
  input[type='checkbox']:after {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: "\f096";
    font-size: 22px;
    top: 0px;
    left: 10px;
    color: black;
  }
  .tile-ctr:hover {
    transform: translatey(-5px) scale(1.02);
  }
  input[type="checkbox"]:checked:after {
    font-weight: 900;
    content: "\f14a";
    color: black;
  }
  label {
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    position: absolute;
    top: 0;
  }
  label img {
    height: 70%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  